<template>
  <transition :name="transition">
    <div
      v-if="type == activeMenu"
      class="header__expand"
      :class="['header__' + type]"
      :data-transition="transition">
      <div class="header__expand__top row">
        <div class="column">
          <slot name="top"></slot>
        </div>
      </div>

      <div class="header__expand__content row">
        <div class="column">
          <slot name="content"></slot>
        </div>
      </div>

      <div v-if="hasBottomSlot" class="header__expand__bottom row">
        <div class="column">
          <slot name="bottom"></slot>
        </div>
      </div>

      <button @click="closeHeaderMenu" class="header__expand__close">
        <SvgIcon name="close" title="Close" />
      </button>
    </div>
  </transition>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import SvgIcon from '@/components/global/SvgIcon'

  export default {
    name: 'Expand',

    components: {
      SvgIcon,
    },

    props: {
      type: {
        type: String,
        default: null,
      },
      transition: {
        type: String,
        default: null,
      },
    },

    computed: {
      hasBottomSlot() {
        return !!this.$slots.bottom
      },
      ...mapState({
        activeMenu: state => state.header.activeMenu,
      }),
    },

    methods: {
      ...mapActions(['closeHeaderMenu']),
    },

    mounted() {
      document.addEventListener('keyup', event => {
        if (event.keyCode === 27) {
          this.closeHeaderMenu()
        }
      })
    },
  }
</script>
